import './network_manager'
import Device from './device_manager'
import './serviceworker_registration'
import NotificationManager from "./notification_manager";
import device from "./device_manager";
import NotificationService from "./push_notifications";

document.addEventListener("DOMContentLoaded", function () {
  Device.init();
});

window.addEventListener('load', function () {
  // wait for 'everything' to load
  const initialized = NotificationManager.init();
  NotificationService.init();

  if (initialized && !device.isIos()) {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      console.log('%c beforeinstall prompt success', 'background: green; color: #fff');
      NotificationManager.showRegisterElement(e);
    });
  }
});
