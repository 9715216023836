import Storage from '../polyfills/storage'
import Helper from "../polyfills/helper";
import CookieStorage from "../polyfills/cookie";

const Device = (function() {

  let version = '1.02';

  'use strict';

  function browser() {
    const ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') != -1) {
      if (ua.indexOf('chrome') > -1) {
        return 'chrome'
      } else {
        return 'safari'
      }
    }

    return ''
  }

  function deviceType() {
    if (isIos()) { return 'ios_pwa' }

    return 'android_pwa'
  }

  function isSafari() {
    return browser() == 'safari'
  }

  function isChrome() {
    return browser() == 'chrome'
  }

  function isIos(){
    return /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase());
  }

  function isStandAlone() {
    return ('standalone' in window.navigator) && (window.navigator.standalone) || window.matchMedia('(display-mode: standalone)').matches;
  }

  function init() {
    checkVersion();

    if (window.matchMedia('(display-mode: standalone)').matches) {
      if (isIos()){
        Storage.setItem('device_type', 'ios_pwa')
      } else {
        Storage.setItem('device_type', 'android_pwa')
      }
    } else {
      if (Storage.getItem('device_type') == 'android_pwa' || Storage.getItem('device_type') == 'ios_pwa' ) {
        //reset
        Storage.setItem('device_type', 'browser')
      }
    }
  }

  function checkVersion() {
    const storedVersion = Storage.getItem('device_manager_version');
    if (storedVersion !== version) {
      Storage.setItem('device_type', '')
      Storage.setItem('device_manager_version', version);
    }
  }

  function uuid() {
    const storedUuid = CookieStorage.getCookie('uuid')
    if (Helper.isEmpty(storedUuid)){
      CookieStorage.setCookie('uuid', generateUuid())
      return uuid()
    }

    return storedUuid;
  }

  function generateUuid(){
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  return {
    isIos: isIos,
    isStandAlone: isStandAlone,
    deviceType: deviceType,
    browser: browser,
    init: init,
    isChrome: isChrome,
    isSafari: isSafari,
    uuid: uuid
  }
})();

export default Device;
