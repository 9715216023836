import Api from "../ajax/api";
import Device from "./device_manager";
import Storage from '../polyfills/storage'
import Switch from "../form/switch";
import Helper from "../polyfills/helper";
import ErrorHandler from "../polyfills/errors";
import CookieStorage from "../polyfills/cookie";

const NotificationService = (function () {

  // Your web app's Firebase configuration
  const firebaseConfig = {
    apiKey: "AIzaSyB9LJbi1aVpzAKFm6lg-A_jQDM-bZzwwlk",
    authDomain: "buzz-e3304.firebaseapp.com",
    databaseURL: "https://buzz-e3304.firebaseio.com",
    projectId: "buzz-e3304",
    storageBucket: "buzz-e3304.appspot.com",
    messagingSenderId: "1039858984552",
    appId: "1:1039858984552:web:64cc2e75d6eacb2fcd7ddd"
  };

  let version = '1.03';
  const PUSH_TOKEN = 'PUSH_TOKEN';
  const PUSH_TOKEN_SENT = 'PUSH_TOKEN_SENT';

  let registerElements = null;
  let messaging = {};

  function init() {
    console.log('%c init', 'background: #222; color: #bada55');
    checkVersion();

    registerElements = Array.from(document.querySelectorAll('[data-register-push]'));
    if (Helper.isEmpty(registerElements)) {
      console.log('%c no register elements, returning', 'background: #222; color: #bada55');
      return;
    }

    if (typeof (firebase) === 'undefined') {
      console.log('%c no firebase, returning', 'background: #222; color: #bada55');
      toggleRegisterElement(false)
      return;
    } else {
      setRegisterTriggers(true)
    }

    try {
      firebase.initializeApp(firebaseConfig);
      messaging = firebase.messaging();
      messaging.usePublicVapidKey("BEmonDAXhzfdL-MXrqGDxuv1igTjJLYgsPwpIxS1bBgWoF0g5eSytBB5COCY1cmRMKTKJ7P1siXHQ5tO3vl8PYc")

      hookUpRefreshTokenHandler(messaging);
      hookUpForeGroundMessageReceivedHandler(messaging);

      setRegisterElementListeners();

      if (!isTokenSendToServer() && isRegistered()) {
        reSendTokenToServer()
      }

      if (isTokenSendToServer() && isRegistered()) {
        console.log('%c registered and token already send to server', 'background: #222; color: #bada55');
        sendPushNotificationEnabledEvent();
        setRegisterTriggers(false)

      } else {
        sendPushNotificationDisabledEvent();
        setRegisterTriggers(true);
      }
    } catch (e) {
      toggleRegisterElement(false)
    }
  }

  function setRegisterTriggers(enable) {
    const triggers = Array.from(document.querySelectorAll('[data-trigger-register-push]'))
    if (enable == false){
      triggers.forEach(trigger => trigger.classList.add('hidden'));
      console.log('%c hiding push message triggers', 'background: #222; color: #bada55');
    } else {
      console.log('%c showing push message triggers', 'background: #222; color: #bada55');
      triggers.forEach(trigger => trigger.classList.remove('hidden'));
      triggers.forEach(trigger => {
        trigger.removeEventListener('click', showRegisterElement)
        trigger.addEventListener('click', showRegisterElement)
      })
    }
  }

  function setRegisterElementListeners() {
    registerElements.forEach(registerElement => {
      registerElement.querySelector('button').removeEventListener('click', registerForPushNotifications);
      registerElement.querySelector('button').addEventListener('click', registerForPushNotifications);
      registerElement.querySelector('[data-dismiss]').removeEventListener('click', hideRegisterElements);
      registerElement.querySelector('[data-dismiss]').addEventListener('click', hideRegisterElements);
    })
  }

  function hideRegisterElements() {
    toggleRegisterElement(false)
  }

  function showRegisterElement() {
    toggleRegisterElement(true)
  }

  function toggleRegisterElement(value) {
    registerElements.forEach(registerElement => {
      if (value == true) {
        console.log('%c showing register elements', 'background: #222; color: #bada55');
        registerElement.classList.add('show');
      } else {
        console.log('%c hiding register elements', 'background: #222; color: #bada55');
        registerElement.classList.remove('show');
      }
    })
  }

  function hookUpRefreshTokenHandler(messaging) {
    messaging.onTokenRefresh(() => {
      messaging.getToken().then((refreshedToken) => {
        console.log('%c token received from firebase', 'background: #222; color: #bada55');
        sendTokenToServer(refreshedToken);
      }).catch((e) => {
        ErrorHandler.captureException(e)
      });
    });
  }

  function hookUpForeGroundMessageReceivedHandler(messaging) {
    messaging.onMessage((payload) => {
      console.log('%c push message recieved', 'background: #222; color: #bada55');
      sendNotificationEvent(payload);
    });
  }

  function sendNotificationEvent(notification) {
    Helper.dispatchEvent('notifications::push_notification_received', notification)
  }

  function registerForPushNotifications() {
    hideRegisterElements();

    console.log('%c registering for push notifications', 'background: #222; color: #bada55');
    messaging.requestPermission().then(function () {
      return messaging.getToken();
    }).then(function (token) {
      console.log('%c sucessfully registered', 'background: #222; color: #bada55');
      console.log(token)
      sendTokenToServer(token);
    }).catch(function (e) {
      console.log('%c not sucessfully registered', 'background: #222; color: #bada55');
      ErrorHandler.captureException(e)
      toggleRegisterElement(false)
      CookieStorage.setCookieWithExpiration('push_notification_hidden', '1', 1000)
    });
  }

  function sendTokenToServer(token, destroy = false) {
    console.log('%c sending token to server', 'background: #222; color: #bada55');
    const request = new Request('/pwa/devices');
    setRegistered(token);

    Api.callApi(request, 'POST', {
      token: token,
      destroy: destroy,
      device_type: Device.deviceType(),
      uuid: Device.uuid()
    }).then((result) => {
      if (result.status == '200') {
        console.log('%c ok response from server', 'background: #222; color: #bada55');
        setTokenSendToServer();
        setRegisterElementListeners();
        sendPushNotificationEnabledEvent();
        toggleRegisterElement(false);
        setRegisterTriggers(false);
      } else {
        console.log('%c error response from server', 'background: #222; color: #bada55');
        setRegisterElementListeners()
        sendPushNotificationDisabledEvent();
        toggleRegisterElement(false);
      }
    }).catch(ex => {
      ErrorHandler.captureException(ex)
      setRegisterElementListeners();
      sendPushNotificationDisabledEvent();
      toggleRegisterElement(false);
      setRegisterTriggers(false)
    });
  }

  function reSendTokenToServer() {
    if (isRegistered() === false) {
      return;
    }

    const token = Storage.getItem(PUSH_TOKEN);
    if (token) {
      sendTokenToServer(token)
    }
  }

  function sendPushNotificationEnabledEvent() {
    Helper.dispatchEvent('notifications::push_notification_enabled')
  }

  function sendPushNotificationDisabledEvent() {
    Helper.dispatchEvent('notifications::push_notifications_disabled')
  }

  function isRegistered() {
    return !Helper.isEmpty(Storage.getItem(PUSH_TOKEN))
  }

  function setRegistered(token) {
    Storage.setItem(PUSH_TOKEN, token)
  }

  function isTokenSendToServer() {
    return !Helper.isEmpty(Storage.getItem(PUSH_TOKEN_SENT))
  }

  function setTokenSendToServer() {
    Storage.setItem(PUSH_TOKEN_SENT, '1')
  }

  function checkVersion() {
    const storedVersion = Storage.getItem('push_manager_version');
    if (storedVersion !== version) {
      Storage.setItem(PUSH_TOKEN, '');
      Storage.setItem('push_manager_version', version);
    }
  }

  return {
    registerForPushNotifications: registerForPushNotifications,
    sendTokenToServer: sendTokenToServer,
    reSendTokenToServer: reSendTokenToServer,
    toggleRegisterElement: toggleRegisterElement,
    init: init
  }
})();

export default NotificationService
