(function() {
  window.addEventListener('load', () => {
    if ('serviceWorker' in navigator) {
      try {
        navigator.serviceWorker.register('/service-worker.js').then(function (registration) {
        }).catch(function (e) {
          // Raven.captureException(e);
        });
      } catch (e) {
        // Raven.captureException(e);
      }
    }
  });
})();
