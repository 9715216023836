import device from "./device_manager";
import CookieStorage from '../polyfills/cookie'
import Switch from "../form/switch";
import Storage from "../polyfills/storage";

const NotificationManager = (function () {

  let deferredPrompt;
  let registerElements;

  function init() {
    registerElements = document.querySelectorAll('[data-register-device]');
    if (!registerElements || registerElements.length == 0) {
      console.log('%c no register elements', 'background: green; color: #fff');
      return false;
    }

    document.addEventListener("online_changed", function (event) {
      if (event.detail.network_status === "offline") {
        console.log('%c offline, hiding registered element', 'background: green; color: #fff');
        hideRegisterElements();
      }
    });

    if (isRegistered() === false) {
      console.log('%c not registered', 'background: green; color: #fff');
      // if (device.isIos() && !device.isStandAlone()) {
      //   showRegisterElement(null)
      // }
    }

    if (CookieStorage.getCookie('add_to_home_hidden')) {
      console.log('%c add to home hidden active', 'background: green; color: #fff');
      return false;
    }

    if (Storage.isAvailable()) {
      const visits = Storage.getItem('visits');
      const visitsCount = visits ? parseInt(visits) : 0;

      console.log('%c visits: ' + visitsCount, 'background: green; color: #fff');
      Storage.setItem('visits', visitsCount + 1);

      if (visitsCount < 20){

        return false;
      }
    }

    return true;
  }

  function showRegisterElement(e) {
    if (!registerElements || registerElements.length == 0) { return; }

    deferredPrompt = e;
    registerElements.forEach(registerElement => {
      registerElement.classList.add('show');
      console.log('%c showing registered element', 'background: green; color: #fff');
      if (deferredPrompt) {
        registerElement.querySelector('button').removeEventListener('click', showNativeNotification);
        registerElement.querySelector('button').addEventListener('click', showNativeNotification);
        registerElement.querySelector('[data-dismiss]').removeEventListener('click', hideRegisterElements);
        registerElement.querySelector('[data-dismiss]').addEventListener('click', hideRegisterElements);
      }
    });
  }

  function showIosAddToHomeScreenNotification(e) {
    if (e.currentTarget.checked == false) {
      return;
    }

    const popUpElement = document.getElementById('addToHomeIosPopup')
    Expander.show(popUpElement)

    Switch.setSwitch(e.currentTarget, false);
  }

  function showNativeNotification(e) {
    console.log('%c showing native notification', 'background: #222; color: #bada55');
    if (e.currentTarget.checked == false) {
      return;
    }

    handleDeferredPrompt(e.currentTarget);
  }

  function handleDeferredPrompt(element) {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice
        .then(function (choiceResult) {
          if (choiceResult.outcome === 'accepted') {
            if (device.isIos()) {
              CookieStorage.setCookie('device_type', 'ios_pwa')
            } else {
              CookieStorage.setCookie('device_type', 'android_pwa')
            }
            location.reload();
          } else {
            hideRegisterElements();
            //TODO ergens iets opslaan dat de gebruiker hem bewust heeft geannuleerd?
          }
          deferredPrompt = null;
        });
    }
  }

  function hideRegisterElements() {
    let expirationDelay = 30;

    if (CookieStorage.getCookie('add_to_home_hidden') > 0) {
      expirationDelay = CookieStorage.getCookie('add_to_home_hidden') * 2;
    }

    CookieStorage.setCookieWithExpiration('add_to_home_hidden', expirationDelay, expirationDelay)
    registerElements.forEach(registerElement => {
      registerElement.classList.remove('show');
    });
  }

  function isRegistered() {
    return CookieStorage.getCookie('device_type') != null
  }

  return {
    showRegisterElement: showRegisterElement,
    init: init
  }
})();

export default NotificationManager;
