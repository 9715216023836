import device from '../pwa/device_manager'
import Helper from "../polyfills/helper";

const networkState = (function() {

  'use strict';

  window.addEventListener('offline', function(_event){
    showOffline();
  });

  window.addEventListener('online', function(_event){
    showOnline();
  });

  window.addEventListener('load', function() {
    if (!navigator.onLine){
      showOffline();
    } else {
      showOnline();
    }
  });

  function showOffline(){
    Array.from(document.querySelectorAll('.online')).forEach(function(element){
      element.classList.add('hidden')
    });

    Array.from(document.querySelectorAll('.offline')).forEach(function(element){
      element.classList.remove('hidden')
    });

    Helper.dispatchEvent('online_changed', {network_status: 'offline'})
  }

  function showOnline(){
    Array.from(document.querySelectorAll('.online')).forEach(function(element){
      element.classList.remove('hidden')
    });

    Array.from(document.querySelectorAll('.offline')).forEach(function(element){
      element.classList.add('hidden')
    });

    Helper.dispatchEvent('online_changed', {network_status: 'online'});

    if (window.location.pathname === "/offline") {
      const online_link = document.getElementById('back_online_link')
      if (online_link) {
        window.location = online_link.href + '?device_type=' + device.deviceType();
      }
    }
  }

  return {
    showOnline: showOnline,
    showOffline: showOffline
  }
})();
